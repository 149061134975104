import { stg } from "./stg";
import { prod } from "./prod";

let DOMAIN_LIST = stg;
switch (process.env.REACT_APP_ENV) {
  case "staging":
    DOMAIN_LIST = stg;
    break;
  case "production":
    DOMAIN_LIST = prod;
    break;
  default:
    DOMAIN_LIST = stg;
}

const {
  CS_DASHBOARD_DOMAIN,
  PRESCRIPTION_GENERATOR_DOMAIN,
} = DOMAIN_LIST; 

const URL_LIST = {
  LOGIN: CS_DASHBOARD_DOMAIN + "login",
  LOGIN_V2: CS_DASHBOARD_DOMAIN + "login/v2",
  VERIFY_TOKEN: CS_DASHBOARD_DOMAIN + "verifyToken",
  CREATE_ACCOUNT: CS_DASHBOARD_DOMAIN + "createAccount",
  LIST_USERS: CS_DASHBOARD_DOMAIN + "listUsers",
  UPDATE_ACTIVE_STATUS: CS_DASHBOARD_DOMAIN + "updateActiveStatus",
  SEARCH_APPOINTMENTS: CS_DASHBOARD_DOMAIN + "searchAppointments",
  GET_PRESCRIPTION_BY_ORDER_ID: CS_DASHBOARD_DOMAIN + "getPrescriptionByOrderId",
  GET_PDF_URL: CS_DASHBOARD_DOMAIN + "prescription/pdf",
  SEND_POST_REQUEST: CS_DASHBOARD_DOMAIN + "sendPostRequest",
  SEARCH_CUSTOMER: CS_DASHBOARD_DOMAIN + "search/customer",
  SEARCH_CUSTOMER_PASSES: CS_DASHBOARD_DOMAIN + "search/customer-so-pass",
  EXPIRE_CUSTOMER_PASSES: CS_DASHBOARD_DOMAIN + "expire-customer-so-pass",
  CREATE_CUSTOMER_PASSES: CS_DASHBOARD_DOMAIN + "add-customer-so-pass",
  WALLET: CS_DASHBOARD_DOMAIN + "wallet",
  ORDER: CS_DASHBOARD_DOMAIN + "order",
  RETRY_UNICOMMERCE: CS_DASHBOARD_DOMAIN + "order/retry-unicommerce",
  NOTES: CS_DASHBOARD_DOMAIN + "notes",
  SEARCH_CUSTOMER_BY_APPOINTMENT_ID: CS_DASHBOARD_DOMAIN + "searchCustomerByAppointmentId",
  UPDATE_ORDER_STATUS: CS_DASHBOARD_DOMAIN + "updateOrderStatus",
  EVENT_INFORMATION: PRESCRIPTION_GENERATOR_DOMAIN + "eventInformation",
  ORDER_DETAILS: PRESCRIPTION_GENERATOR_DOMAIN + "orderDetails",
  FORM_SUBMIT: PRESCRIPTION_GENERATOR_DOMAIN + "formSubmit",
  CANCEL_AND_REFUND: CS_DASHBOARD_DOMAIN + "cancelAndRefund",
  USER_INFO: CS_DASHBOARD_DOMAIN + "users/details",
  USER_SO_INFO : CS_DASHBOARD_DOMAIN + "getUserSoDetails"
}

export {URL_LIST, DOMAIN_LIST};
